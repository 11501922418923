// common.scss
* {
  font-family: 'Noto Sans KR', sans-serif;
  box-sizing: border-box;
}
html {
  font-size: 16px; // rem 기준
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--bs-dark);
  line-height: 1.6 !important;
  /* Prevent font scaling in landscape */
  -webkit-text-size-adjust: none; /*Chrome, Safari, newer versions of Opera*/
  -moz-text-size-adjust: none; /*Firefox*/
  -ms-text-size-adjust: none; /*Ie*/
  -o-text-size-adjust: none; /*old versions of Opera*/
}
a,
a:hover {
  text-decoration: none;
  color: var(--bs-dark);
}
ul {
  padding-left: 0;
  margin: 0;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
}
li {
  list-style: none;
}
button {
  border: 0;
  outline: 0;
  background: transparent;
}
img {
  max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  line-height: 1.4;
  margin: 0;
}
p,
figure,
dl,
dd {
  margin: 0;
}
::-moz-selection {
  background: rgb(205, 223, 243);
}
::selection {
  background: rgb(205, 223, 243);
}

::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  background: #eee;
}
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

// bootstrap
.row > * {
  min-width: 1px;
}

html,
body,
#root,
.App {
  width: 100%;
  height: 100%;
}

/*---------------------------------------------------------------------
                      ↓ container
-----------------------------------------------------------------------*/
.container-fluid {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding: 0 !important;
  margin-right: auto;
  margin-left: auto;
  background: #fff;
}
.container {
  min-width: 1280px;
  max-width: 1280px;
  padding: 14px 0;
  &.w-1470 {
    min-width: 1470px;
  }
}
/*---------------------------------------------------------------------
                      ↓ card
-----------------------------------------------------------------------*/
.card {
  padding: 1.25rem;
  margin-bottom: 30px;
  border: none;
  border-radius: 4px;
  background-color: #fafcfc;
}

/*---------------------------------------------------------------------
                      ↓ btn
-----------------------------------------------------------------------*/
.btn {
  flex-shrink: 0;
  font-weight: 700;
  font-size: var(--fs-16);
  padding: 0 1.125rem;
  border-radius: 0.25rem;
  line-height: 1.25;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-shadow-rgb), 0.2);
  --bs-btn-active-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-shadow-rgb), 0.5);
  height: 44px;
  // state
  // &:focus {
  //   color: var(--bs-btn-hover-color);
  //   background-color: var(--bs-btn-hover-bg);
  //   border-color: var(--bs-btn-hover-border-color);
  //   box-shadow: var(--bs-btn-focus-box-shadow);
  //   outline: 0;
  // }
  // &:active {
  //   color: var(--bs-btn-active-color);
  //   background-color: var(--bs-btn-active-bg);
  //   border-color: var(--bs-btn-active-border-color);
  //   box-shadow: var(--bs-btn-active-box-shadow);
  //   outline: 0;
  // }
  &:disabled {
    pointer-events: none;
    color: var(--bs-btn-disable-color);
    background-color: var(--bs-btn-disable-bg);
    border-color: var(--bs-btn-disable-border-color);
    opacity: 1;
  }
  // size
  &.btn-sm {
    font-size: var(--fs-14);
    padding: 0 0.75rem;
    height: 34px;
  }
  &.btn-lg {
    font-size: var(--fs-16);
    height: 48px;
    min-width: 250px;
  }
  // variant
  &.btn-primary {
    --bs-btn-bg: var(--bs-primary);
    --bs-btn-border-color: var(--bs-primary);
    --bs-btn-color: var(--bs-white);
    --bs-btn-hover-bg: var(--bs-primary-hover);
    --bs-btn-hover-border-color: var(--bs-primary-hover);
    --bs-btn-hover-color: var(--bs-white);
    --bs-btn-disable-bg: var(--bs-disabled-bg);
    --bs-btn-disable-border-color: var(--bs-disabled-bg);
    --bs-btn-disable-color: var(--bs-disabled-color);
    --bs-btn-shadow-rgb: var(--bs-primary-rgb);
  }
  &.btn-outline-primary {
    --bs-btn-bg: var(--bs-ts);
    --bs-btn-border-color: var(--bs-primary);
    --bs-btn-color: var(--bs-primary);
    --bs-btn-hover-bg: var(--bs-primary-light);
    --bs-btn-hover-border-color: var(--bs-primary);
    --bs-btn-hover-color: var(--bs-primary);
    --bs-btn-disable-bg: var(--bs-ts);
    --bs-btn-disable-border-color: var(--bs-gray);
    --bs-btn-disable-color: var(--bs-gray);
    --bs-btn-shadow-rgb: var(--bs-primary-rgb);
  }
  &.btn-ts {
    --bs-btn-bg: var(--bs-ts);
    --bs-btn-border-color: var(--bs-ts);
    --bs-btn-color: var(--bs-dark);
    --bs-btn-hover-bg: var(--bs-primary-light);
    --bs-btn-hover-border-color: var(--bs-primary-light);
    --bs-btn-hover-color: var(--bs-dark);
    --bs-btn-disable-bg: var(--bs-ts);
    --bs-btn-disable-border-color: var(--bs-ts);
    --bs-btn-disable-color: var(--bs-gray);
    --bs-btn-shadow-rgb: var(--bs-gray-blue-rgb);
  }
  &.btn-outline-gray {
    background-color: white;
    color: var(--bs-gray);
    border: 1px solid var(--bs-gray) !important;
    &:hover {
      color: white;
      background-color: var(--bs-gray);
      border: 1px solid var(--bs-gray) !important;
    }
  }
  &.btn-outline-success {
    border: 1px solid var(--bs-success) !important;
    &:hover {
      color: white;
    }
  }
  &.btn-primary-light {
    background: var(--bs-primary-light) !important;
    border: 1px solid var(--bs-primary) !important;
    color: var(--bs-primary) !important;
    &:hover {
      background: #cbd6ff !important;
    }
  }
  &.btn-view-more {
    height: 28px;
    line-height: 28px;
    padding: 0;
    padding-right: 30px;
    font-size: var(--fs-12);
    font-weight: bold;
    color: var(--bs-dark) !important;
    border: none !important;
    background: transparent url('../images/ic_arrow_right.png') no-repeat 82%
      50% / 7px !important;
    transition: 0.15s ease-in-out;
  }
  &.btn-text {
    color: #999 !important;
    background: transparent !important;
    border: none !important;
    font-size: 16px;
    padding: 0;
    position: relative;
    cursor: pointer;
    &:hover {
      color: var(--bs-primary) !important;
      background: transparent !important;
    }
  }
  &.btn-text-underline {
    height: auto;
    color: #326bff !important;
    background: transparent !important;
    border: none !important;
    padding: 0;
    position: relative;
    cursor: pointer;
    font-weight: bold;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: 2px;
      left: 0;
      background: #326bff;
    }
    &:hover {
      color: #326bff !important;
      &:before {
        background: #326bff;
      }
    }
  }
  &.btn-plus {
    height: 42px;
    width: 42px;
    border-radius: 4px;
    background: var(--bs-primary) url('../images/ic_plus.png') no-repeat center /
      14px !important;
    transition: 0.15s ease-in-out;
    &:hover {
      background: var(--bs-primary-hover) url('../images/ic_plus.png') no-repeat
        center / 14px !important;
    }
  }
  &.btn-minus {
    height: 42px;
    width: 42px;
    border-radius: 4px;
    border: 1px solid var(--bs-primary);
    background: transparent url('../images/ic_minus_primary.png') no-repeat
      center / 14px !important;
    transition: 0.15s ease-in-out;
    &:hover {
      background: var(--bs-primary) url('../images/ic_minus.png') no-repeat
        center / 14px !important;
    }
  }
  &.btn-icon {
    height: auto;
    color: #191919;
    background: transparent !important;
    border: none !important;
    font-size: 16px;
    padding: 0;
    padding-left: 32px;
    &.ic-down {
      display: block;
      background: transparent url('../images/ic_download.png') no-repeat 0% 50% !important;
      transition: 0.15s ease-in-out;
      text-align: left;
      min-width: 1px;
      padding-left: 28px;
      font-weight: 500;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-wrap: normal;
      overflow: hidden;
      width: 100%;
      margin: 0.5rem 0;
    }
    &:hover {
      color: var(--bs-primary);
      &.ic-down {
        background-image: url('../images/ic_download_on.png') !important;
      }
    }
  }
  &.btn-iconOnly {
    height: auto;
    min-width: 1px;
    padding: 0;
    background-color: transparent !important;
    border: none !important;
    &.btn-sm {
      img {
        width: 18px;
        height: 18px;
      }
    }
    img {
      width: 20px;
      height: 20px;
    }
    &:hover {
      background-color: transparent !important;
      border: none !important;
    }
  }
  &.btn-delete {
    border: none !important;
    color: var(--bs-white);
    min-width: 28px;
    height: 28px;
    padding: 0;
    background: rgba(0, 0, 0, 0.1) url(../images/ic_modal_close.png) no-repeat
      center / 12px !important;
    &:hover {
      border: none !important;
      background: rgba(0, 0, 0, 0.2) url(../images/ic_modal_close.png) no-repeat
        center / 12px !important;
    }
  }
}

/*---------------------------------------------------------------------
                      ↓ form-control
-----------------------------------------------------------------------*/

.form-control {
  height: 42px;
  line-height: 1.5;
  padding: 0 0.75rem;
  border-radius: 0.25rem;
  border: 1px solid var(--bs-border);
  font-size: 1rem;
  color: #18191c;
  background: white;
  &.input-datepicker {
    font-size: 1rem;
    background: white url('../images/ic_calendar.png') no-repeat 93% 44% / 16px;
  }
  &.input-search {
    background: white url('../images/ic_search.png') no-repeat 0.75rem 50% /
      14px;
    height: 42px;
    line-height: 42px;
    padding-left: 38px;
    font-size: var(--fs-16);
  }
  &[disabled] {
    color: #6c757d !important;
    background-color: #e9ecef !important;
    opacity: 0.7 !important;
  }
  &::-webkit-input-placeholder {
    color: #a4a5b6;
    font-weight: 400;
  }
  &::-moz-placeholder {
    color: #a4a5b6;
    font-weight: 400;
  }
  &:-ms-input-placeholder {
    color: #a4a5b6;
    font-weight: 400;
  }
  &::-ms-input-placeholder {
    color: #a4a5b6;
    font-weight: 400;
  }
  &::placeholder {
    color: #a4a5b6;
    font-weight: 400;
  }
}
.form-label {
  margin-bottom: 0.3rem !important;
}
.form-control.is-invalid {
  border-color: #dc3545 !important;
  background-image: none !important;
  padding-right: 12px;
  box-shadow: 0px 0px 0px 4px rgba(223, 17, 37, 0.2);
}
.form-select {
  padding: 0 0.75rem;
  line-height: 42px;
  height: 42px;
  border: 1px solid var(--bs-border);
  border-radius: 0.25rem;
  background-image: url('../images/ic_down.png');
  background-size: 10px auto;
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  font-weight: 500;
  font-size: var(--fs-16);
}
.was-validated {
  .form-control {
    &:invalid,
    &:valid {
      background-image: none !important;
      padding-right: 14px;
    }
    &:valid {
      border: 1px solid #d4d8df !important;
    }
  }
}
.invalid-feedback {
  text-align: left;
}

/*---------------------------------------------------------------------
                      ↓ textarea
-----------------------------------------------------------------------*/
textarea.form-control {
  height: auto;
  padding: 16px 14px 16px 16px;
  line-height: 1.6 !important;
  overflow: scroll;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 6px;
  }
}

/*---------------------------------------------------------------------
                     ↓ form-check(라디오, 체크박스)
-----------------------------------------------------------------------*/
.form-check {
  margin-bottom: 0;
  padding: 0;
  display: inline-flex;
  align-items: center;
  .form-check-input {
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .form-check-label {
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    color: var(--bs-dark);
  }
  .form-check-input[type='radio'] {
    margin: 0 !important;
    width: 24px;
    height: 24px;
    border: none;
    background: url('../images/radio_primary.png ') no-repeat center;
    &:checked[type='radio'] {
      background: url('../images/radio_checked_primary.png') no-repeat center /
        24px;
    }
    &:disabled[type='radio'] {
      opacity: 1;
      background: url('../images/radio_disabled.png ') no-repeat center;
      &:checked[type='radio'] {
        background: url('../images/radio_checked_disabled.png') no-repeat center /
          24px;
      }
    }
  }
  .form-check-input[type='checkbox'] {
    margin: 0 0.5rem 0 0;
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    background: url('../images/checkbox.png') no-repeat center / 24px;
    &:checked[type='checkbox'] {
      margin: 0 0.5rem 0 0;
      background: url('../images/checkbox_checked.png') no-repeat center / 24px;
    }
    &[readonly],
    &[disabled] {
      background: url('../images/checkbox_disabled.png') no-repeat center / 24px !important;
      opacity: 1 !important;
    }
  }
  &.type-priamry {
    .form-check-input[type='radio'] {
      background: url('../images/radio_primary.png ') no-repeat center;
      &:checked[type='radio'] {
        background: url('../images/radio_checked_primary.png') no-repeat center;
      }
    }
  }
  &.only-input {
    .form-check-input {
      margin: 0 !important;
    }
    .form-check-label {
      height: 24px;
    }
  }
}
.radio-group {
  display: flex;
  flex-wrap: wrap;
  &.type-flex-1 {
    width: 100%;
    .form-check {
      width: 20%;
      text-align: center;
      justify-content: center;
      margin-right: 0;
      .form-check-label {
        padding-left: 0;
      }
    }
  }
  .form-check {
    min-height: 38px;
    margin-right: 1.4rem;
    .form-check-label {
      cursor: pointer;
      font-size: 0.9rem;
      font-weight: 500;
      color: #323232;
      height: 20px;
      line-height: 20px;
      padding-left: 6px;
    }
  }
  &.flex-end {
    justify-content: flex-end;
    .form-check {
      margin-right: 0;
      margin-left: 1.4rem;
    }
  }
}
/*---------------------------------------------------------------------
                     ↓ 카드 라디오버튼(교사 코멘트 화면)
-----------------------------------------------------------------------*/
.card-radio-group {
  h5 {
    font-size: 18px !important;
    color: #36363b;
    margin-bottom: 12px;
  }
  .form-check {
    margin-right: 8px;
    .form-check-label {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      cursor: pointer;
      min-width: 180px;
      height: 54px;
      line-height: 1;
      padding: 0 0 3px 48px;
      font-size: 17px;
      font-weight: bold;
      color: #323232;
      border: 2px solid #dfe0e7;
      border-radius: 6px;
      background: transparent url('../images/radio_gray.png ') no-repeat 10% 50%;
    }
    .form-check-input[type='radio'] {
      display: none;
      &:checked[type='radio'] {
        & + .form-check-label {
          color: var(--bs-primary);
          border: 2px solid var(--bs-primary);
          background: white url('../images/radio_checked_primary.png') no-repeat
            10% 50%;
        }
      }
    }
  }
}

/*---------------------------------------------------------------------
                      ↓ dropdown
-----------------------------------------------------------------------*/
.dropdown {
  // 기본 드롭다운
  .dropdown-toggle {
    &:focus,
    &:active {
      outline: none !important;
    }
    justify-content: flex-start !important;
    position: relative;
    text-align: left;
    border-radius: 4px;
    min-width: 104px;
    padding: 0 13px;
    margin-left: 0 !important;
    height: 42px;
    font-size: 0.9rem;
    font-weight: 500;
    color: #222 !important;
    border: 1px solid #dedede;
    // border-color: #dedede;
    background: #fff !important;
    transition: none !important;
    &::after {
      display: none;
    }
    &::before {
      content: '';
      position: absolute;
      top: 18px;
      bottom: 0;
      margin: auto;
      right: 16px;
      border-top: 5px solid #666;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 0px solid transparent;
    }
    &:disabled {
      color: #6c757d !important;
      background-color: #e9ecef !important;
      opacity: 0.7 !important;
      border: 1px solid var(--bs-border);
      &::before {
        border-top: 6px solid #8e8e8e;
      }
    }
  }
  .dropdown-menu.show {
    width: 100%;
    min-width: 104px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-radius: 8px;
    background: #fff !important;
    border-color: #f2f2f2;
    color: #222 !important;
    box-shadow: 5px 5px 5px 5px rgb(0 0 0 / 10%);
    padding: 8px;
    font-size: 0.9rem;
    justify-content: flex-start !important;
    .dropdown-item {
      border-radius: 4px;
      padding: 8px;
      color: #18191c;
      &:hover {
        background: #f2f2f2 !important;
      }
      &.selected {
        color: #18191c;
        background: #f2f2f2;
      }
    }
  }
}

/*---------------------------------------------------------------------
                      ↓ tab
-----------------------------------------------------------------------*/
.nav {
  &.basic-tabs {
    .nav-item {
      display: flex;
      .nav-link {
        cursor: pointer;
        padding: 0 16px;
        width: 260px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        color: #191919;
        background-color: var(--bs-white);
        border: 1px solid #bcbfcb;
      }
      .nav-link.active {
        color: var(--bs-white);
        background-color: var(--bs-primary);
        border: var(--bs-primary);
      }
    }
  }
  &.text-tabs {
    .nav-item {
      display: flex;
      .nav-link {
        cursor: pointer;
        padding: 0 16px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        color: #a4a5b6;
        border: none;
      }
      .nav-link.active {
        color: var(--bs-primary);
        font-weight: bold;
      }
    }
  }
  &.border-tabs {
    .nav-item {
      display: flex;
      width: 100%;
      .nav-link {
        flex: 1;
        cursor: pointer;
        padding: 0 16px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        color: #a4a5b6;
        background-color: #ecf1f4;
        border: 1px solid #ecf1f4;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      .nav-link.active {
        font-weight: bold;
        color: var(--bs-primary);
        background-color: var(--bs-white);
        border: 1px solid black;
        border-bottom-color: white;
      }
    }
  }
}
.tab-content {
  height: 100%;
  .tab-pane {
    height: 100%;
  }
}

/*---------------------------------------------------------------------
                      ↓ pagination
-----------------------------------------------------------------------*/
.pagination {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  .page-item {
    .page-link {
      min-width: 30px;
      height: 30px;
      line-height: 30px;
      padding: 0 !important;
      margin-left: 8px;
      font-size: 13px;
      font-weight: 600;
      text-align: center;
      border-radius: 4px;
      background-color: transparent;
      color: var(--bs-primary);
      border-color: #aac4e0;
      &:focus,
      &:active {
        border-color: var(--bs-primary);
        outline: none !important;
        box-shadow: none !important;
      }
    }
    &.active {
      .page-link {
        background-color: var(--bs-primary) !important;
        border-color: var(--bs-primary);
        color: white !important;
      }
    }
    // next, prev disabled
    &.disabled {
      &:first-of-type {
        .page-link {
          background: url(../images/ic_page_prev_disabled.png) no-repeat center /
            6px;
        }
      }
      &:last-of-type {
        .page-link {
          background: url(../images/ic_page_next_disabled.png) no-repeat center /
            6px;
        }
      }
    }
    // next, prev custom
    &:first-of-type {
      .page-link {
        margin-left: 0;
        border-color: #bec5ce;
        background: url(../images/ic_page_prev.png) no-repeat center / 6px;
      }
    }
    &:last-of-type {
      .page-link {
        border-color: #bec5ce;
        background: url(../images/ic_page_next.png) no-repeat center / 6px;
      }
    }
  }
}

/*---------------------------------------------------------------------
                      ↓ loading spinner
-----------------------------------------------------------------------*/
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.spinner-overlay.show {
  visibility: visible;
  opacity: 1;
}
.spinner-center {
  align-self: center;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loading-text {
  margin-top: 20px;
  font-size: 25px;
  font-weight: 500;
  color: white;
  text-align: center;
}
/*---------------------------------------------------------------------
                      ↓ 기타
-----------------------------------------------------------------------*/
// 윤곽선 제거
.form-control,
.btn,
.form-check-input,
.input-datepicker,
.accordion-button {
  &:focus,
  &:active {
    // outline: #333 !important;
    outline: none !important;
    box-shadow: none !important;
  }
}

// 말줄임
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
// HOVER
@media (min-width: 1025px) {
  @media (hover: hover) {
    .btn {
      &:hover {
        color: var(--bs-btn-hover-color);
        background-color: var(--bs-btn-hover-bg);
        border-color: var(--bs-btn-hover-border-color);
      }
    }
  }
}
