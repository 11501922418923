// reset.scss
@import url('//cdn.jsdelivr.net/npm/xeicon@2.3.3/xeicon.min.css');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap');

// BootStrap Root Reset
$theme-colors: (
  'primary': #004ea2,
  'primary-hover': #2147aa,
  'primary-light': #ebf0ff,
  'disabled-bg': #d4d8df,
  'disabled-color': #989da5,

  'dark': #191919,
  'dark-hover': #151515,

  'gray': #72767c,
  'gray-dark': #53535d,
  'gray-light': #a7aab9,
  'gray-bg': #f1f1f1,
  'secondary': #bdbdbd,
  'secondary-hover': #989da5,
  'danger': #ba0e1f,
  'danger-hover': #b1091a,
  'success': #66cb9f,
  'success-hover': #49c08c,
  'warning': #e88b00,
  'info': #0044e5,
  'info-hover': #003bc4,
  'border': #d4d8df,
  // 'border': #bcbfcb,
  'white': #fff,
  'ts': transparent,
);
$font-family-sans-serif: 'Noto Sans KR', sans-serif;
$body-line-height: 1.6;
$body-font-size: 16px;

// add
:root {
  --fs-10: 0.625rem;
  --fs-12: 0.75rem;
  --fs-14: 0.875rem;
  --fs-16: 1rem;
  --fs-18: 1.125rem;
  --fs-20: 1.25rem;
  --fs-24: 1.5rem;
  --fs-28: 1.75rem;
  --fs-32: 2rem;
  --fs-40: 2.5rem;
}

[class*=' xi-'],
[class^='xi-'] {
  font-size: var(--fs-24);
}
