// auth.scss

#sign-in {
  padding-top: 91px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../images/main/main.png');
  // min-height: calc(100vh - 91px);
  .auth-wrap {
    min-width: 1280px !important;
    height: calc(100vh - 90px);
    display: flex;
    align-items: center;
    justify-content: center;
    .card {
      .title {
        justify-content: center;
        margin: 0 0 30px 0;
        h5 {
          font-size: 28px;
        }
      }
      .form-control {
        height: 48px;
        font-size: 16px;
      }
      .form-check {
        .form-check-input[type='checkbox'] {
          margin: 0 0.5rem 0 0;
          border-radius: 2px;
          border: 1px solid #aaaaaa;
          width: 24px;
          height: 24px;
          &:checked[type='checkbox'] {
            background: url('../images/checkbox_checked.png') no-repeat center /
              24px !important;
          }
          &[readonly],
          &[disabled] {
            background: url('../images/checkbox_disabled.png') no-repeat center /
              24px !important;
            opacity: 1 !important;
          }
        }
        .form-check-label {
          font-size: 18px;
          color: #191919;
          height: 18px;
          line-height: 18px;
        }
      }

      width: 480px;
      min-width: 280px !important;
      box-shadow: 0 4px 4px 0 rgb(0 0 0 / 10%);
      padding: 30px;
      border-radius: 4px;
      background-color: white;
    }
  }
}
